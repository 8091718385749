/* eslint-disable react/jsx-pascal-case */
import React, { useContext, useRef, useState } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { SD_Context } from "./context/sd-context";
import New_Nav from "./Components/sd-navbar/sd-new-nav";
import Footer from "./Components/sd-footer/sd-footer";
import Sd_Routes from "./sd-routes";
import UserService from "./services/sd-user.service";
import Sd_error_page from "./pages/sd-error-pages/sd-error-response";
import ErrorBoundary from "./context/sd-error-boundary";
import Maintenance from "./pages/maintenance/maintenance";
import Sd_loading from "./Components/sd-common-components/sd-loading";
import { popbanner, popup4 } from "./Assets/imageList";

const App = React.memo((props) => {
  const { setUserData, display, setDisplay, refContainer, templeID } =
    useContext(SD_Context);
  const refe = useRef(null);
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [loading, setLoading] = React.useState(true);
  const [maintenance, setMaintenance] = useState(false);
  const [trig, setTrig] = useState(false);
  const [trig2, setTrig2] = useState(false);
  const [selectedLanguage, setSelectedlanguage] = useState("");
  const [shift, setShift] = useState(false);

  const fetchOnlineEnable = async () => {
    const res = await UserService.getTempleConfig(templeID);
    if (!res.onlineBookingEnabled) {
      setMaintenance(true);
      setLoading(false);
      return;
    }
  };

  React.useEffect(async () => {
    if (
      supportedLanguages.includes(
        props.history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(props.history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }

    if (localStorage.getItem("accessToken")) {
      checkToken();
    } else {
      setLoading(false);
      setShift(false);
    }
    fetchOnlineEnable();
  }, []);

  const checkToken = async () => {
    try {
      setLoading(true);
      if (localStorage.getItem("accessToken")) {
        let data = await UserService.checkToken(templeID);
        setUserData(data);
        setShift(true);
      }
    } catch (error) {
      localStorage.clear();
    }
    setShift(true);

    setLoading(false);
  };

  const onKeyEvent = (event) => {
    const eventCode = event.which || event.keyCode;
    if (eventCode === 27) {
      if (trig) {
        setDisplay(false);
        // setDisplay2(true);
        setTrig(false);
      }
    }
  };

  const handleKey = (e) => {
    if (!e.target.className) {
      return;
    }
    if (e.target.className === "sd-pop-up") {
      if (trig) {
        setDisplay(false);
        // setDisplay2(true);
        setTrig(false);
      }
    }
  };

  const closeFunction = () => {
    setTimeout(() => {
      setTrig(true);
      try {
        refe.current.focus();
      } catch (e) {
        // console.log("");
      }
    }, 3000);
    setTimeout(() => {
      if (display) {
        setDisplay(false);
      }
    }, 10000);
  };

  return (
    <>
      {!loading && !maintenance ? (
        <div className="sd-main" id="myDIV" name="myDIV">
          <New_Nav />
          <div ref={refContainer}></div>
          <ErrorBoundary>
            <Switch>
              <Route
                path="/:lang"
                render={(props) => {
                  if (
                    props?.history?.location?.pathname?.replaceAll?.(
                      "/",
                      ""
                    ) === props?.match?.params?.lang &&
                    supportedLanguages.includes(props?.match?.params?.lang)
                  ) {
                    return (
                      <Redirect to={`/${props?.match?.params?.lang}/home`} />
                    );
                  }
                  return supportedLanguages.includes(
                    props?.match?.params?.lang
                  ) ? (
                    <Sd_Routes {...props} />
                  ) : (
                    <Redirect
                      to={`/en-in${props?.history?.location?.pathname}`}
                    />
                  );
                }}
              />
              <Route
                path="*"
                render={(props) => {
                  // console.log("%c euhduiheruiuivheurv");
                  return (
                    <Redirect
                      to={`/en-in${props?.history?.location?.pathname}`}
                    />
                  );
                }}
              />
            </Switch>
            <Footer />
          </ErrorBoundary>

          {display &&
          props?.history?.location.pathname === `/${selectedLanguage}/home` ? (
            <div
              tabIndex="-1"
              ref={refe}
              autoFocus
              className={"sd-pop-up"}
              onKeyDown={(e) => onKeyEvent(e)}
              onMouseDown={(e) => {
                handleKey(e);
              }}
            >
              <div className="inside-pop">
                <img src={popup4} alt="" className="s-image" />
                {closeFunction()}
                <button
                  className={"display"}
                  onClick={() => {
                    setDisplay(false);
                    setTrig(false);
                  }}
                >
                  X
                </button>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  <a
                    href="https://aped-tms.fra1.digitaloceanspaces.com/production-public-uploads/75d6303e-4872-496e-8539-6648739add2b-Bhavani_Dheekalu_2024.pdf"
                    className="sd-btn-orange"
                    download={"RTI 4 (1) (b).pdf"}
                  >
                    Download
                  </a>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : maintenance ? (
        <>
          <Maintenance />
        </>
      ) : (
        <Sd_loading />
      )}
      <div className="sd-error">
        <Sd_error_page />
      </div>
    </>
  );
});
App.displayName = "Apps";

export default withRouter(App);
